@import "./_appwork/include";
@import "./_theme/common";

$primary-color: #1F69FF;
$body-bg: #f8f8f8;

body {
  background: $body-bg;
}

@include appwork-common-theme($primary-color);
