.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.text-tiny {
  font-size: 70% !important;
}

.text-small {
  font-size: 85% !important;
}

.text-big {
  font-size: 112% !important;
}

.text-large {
  font-size: 150% !important;
}

.text-xlarge {
  font-size: 170% !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-condenced {
  line-height: 1.3 !important;
}

.line-height-inherit {
  line-height: inherit !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: 900 !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-transform-none {
  text-transform: none !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-auto {
  overflow: auto !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

@media (min-width: 576px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-auto {
    width: auto !important;
  }
}
@media (min-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-auto {
    width: auto !important;
  }
}
@media (min-width: 992px) {
  .w-lg-100 {
    width: 100% !important;
  }
  .w-lg-auto {
    width: auto !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-100 {
    width: 100% !important;
  }
  .w-xl-auto {
    width: auto !important;
  }
}
.mr--0,
.mx--0 {
  margin-right: 0 !important;
}

.ml--0,
.mx--0 {
  margin-left: 0 !important;
}

.mr--1,
.mx--1 {
  margin-right: -0.25rem !important;
}

.ml--1,
.mx--1 {
  margin-left: -0.25rem !important;
}

.mr--2,
.mx--2 {
  margin-right: -0.5rem !important;
}

.ml--2,
.mx--2 {
  margin-left: -0.5rem !important;
}

.mr--3,
.mx--3 {
  margin-right: -1rem !important;
}

.ml--3,
.mx--3 {
  margin-left: -1rem !important;
}

.mr--4,
.mx--4 {
  margin-right: -1.5rem !important;
}

.ml--4,
.mx--4 {
  margin-left: -1.5rem !important;
}

.mr--5,
.mx--5 {
  margin-right: -3rem !important;
}

.ml--5,
.mx--5 {
  margin-left: -3rem !important;
}

@media (min-width: 576px) {
  .mr-sm--0,
  .mx-sm--0 {
    margin-right: 0 !important;
  }
  .ml-sm--0,
  .mx-sm--0 {
    margin-left: 0 !important;
  }
  .mr-sm--1,
  .mx-sm--1 {
    margin-right: -0.25rem !important;
  }
  .ml-sm--1,
  .mx-sm--1 {
    margin-left: -0.25rem !important;
  }
  .mr-sm--2,
  .mx-sm--2 {
    margin-right: -0.5rem !important;
  }
  .ml-sm--2,
  .mx-sm--2 {
    margin-left: -0.5rem !important;
  }
  .mr-sm--3,
  .mx-sm--3 {
    margin-right: -1rem !important;
  }
  .ml-sm--3,
  .mx-sm--3 {
    margin-left: -1rem !important;
  }
  .mr-sm--4,
  .mx-sm--4 {
    margin-right: -1.5rem !important;
  }
  .ml-sm--4,
  .mx-sm--4 {
    margin-left: -1.5rem !important;
  }
  .mr-sm--5,
  .mx-sm--5 {
    margin-right: -3rem !important;
  }
  .ml-sm--5,
  .mx-sm--5 {
    margin-left: -3rem !important;
  }
}
@media (min-width: 768px) {
  .mr-md--0,
  .mx-md--0 {
    margin-right: 0 !important;
  }
  .ml-md--0,
  .mx-md--0 {
    margin-left: 0 !important;
  }
  .mr-md--1,
  .mx-md--1 {
    margin-right: -0.25rem !important;
  }
  .ml-md--1,
  .mx-md--1 {
    margin-left: -0.25rem !important;
  }
  .mr-md--2,
  .mx-md--2 {
    margin-right: -0.5rem !important;
  }
  .ml-md--2,
  .mx-md--2 {
    margin-left: -0.5rem !important;
  }
  .mr-md--3,
  .mx-md--3 {
    margin-right: -1rem !important;
  }
  .ml-md--3,
  .mx-md--3 {
    margin-left: -1rem !important;
  }
  .mr-md--4,
  .mx-md--4 {
    margin-right: -1.5rem !important;
  }
  .ml-md--4,
  .mx-md--4 {
    margin-left: -1.5rem !important;
  }
  .mr-md--5,
  .mx-md--5 {
    margin-right: -3rem !important;
  }
  .ml-md--5,
  .mx-md--5 {
    margin-left: -3rem !important;
  }
}
@media (min-width: 992px) {
  .mr-lg--0,
  .mx-lg--0 {
    margin-right: 0 !important;
  }
  .ml-lg--0,
  .mx-lg--0 {
    margin-left: 0 !important;
  }
  .mr-lg--1,
  .mx-lg--1 {
    margin-right: -0.25rem !important;
  }
  .ml-lg--1,
  .mx-lg--1 {
    margin-left: -0.25rem !important;
  }
  .mr-lg--2,
  .mx-lg--2 {
    margin-right: -0.5rem !important;
  }
  .ml-lg--2,
  .mx-lg--2 {
    margin-left: -0.5rem !important;
  }
  .mr-lg--3,
  .mx-lg--3 {
    margin-right: -1rem !important;
  }
  .ml-lg--3,
  .mx-lg--3 {
    margin-left: -1rem !important;
  }
  .mr-lg--4,
  .mx-lg--4 {
    margin-right: -1.5rem !important;
  }
  .ml-lg--4,
  .mx-lg--4 {
    margin-left: -1.5rem !important;
  }
  .mr-lg--5,
  .mx-lg--5 {
    margin-right: -3rem !important;
  }
  .ml-lg--5,
  .mx-lg--5 {
    margin-left: -3rem !important;
  }
}
@media (min-width: 1200px) {
  .mr-xl--0,
  .mx-xl--0 {
    margin-right: 0 !important;
  }
  .ml-xl--0,
  .mx-xl--0 {
    margin-left: 0 !important;
  }
  .mr-xl--1,
  .mx-xl--1 {
    margin-right: -0.25rem !important;
  }
  .ml-xl--1,
  .mx-xl--1 {
    margin-left: -0.25rem !important;
  }
  .mr-xl--2,
  .mx-xl--2 {
    margin-right: -0.5rem !important;
  }
  .ml-xl--2,
  .mx-xl--2 {
    margin-left: -0.5rem !important;
  }
  .mr-xl--3,
  .mx-xl--3 {
    margin-right: -1rem !important;
  }
  .ml-xl--3,
  .mx-xl--3 {
    margin-left: -1rem !important;
  }
  .mr-xl--4,
  .mx-xl--4 {
    margin-right: -1.5rem !important;
  }
  .ml-xl--4,
  .mx-xl--4 {
    margin-left: -1.5rem !important;
  }
  .mr-xl--5,
  .mx-xl--5 {
    margin-right: -3rem !important;
  }
  .ml-xl--5,
  .mx-xl--5 {
    margin-left: -3rem !important;
  }
}
body {
  -moz-font-feature-settings: "liga" on;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.container,
.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem;
}
@media (min-width: 992px) {
  .container,
  .container-fluid {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

.layout-wrapper,
.layout-inner {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  width: 100%;
}

.layout-wrapper {
  overflow: hidden;
}

.layout-inner {
  min-height: 100vh;
}

.layout-container,
.layout-content,
.layout-content > * {
  min-height: 1px;
}

.layout-container {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  padding: 0;
}
.layout-without-sidenav .layout-container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.layout-content {
  display: flex;
  align-items: stretch;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
}

.layout-1 .layout-inner {
  flex-direction: column;
}
.layout-1 .layout-content {
  flex-basis: 100%;
  width: 0;
  min-width: 0;
  max-width: 100%;
}

html:not(.layout-navbar-fixed):not(.layout-fixed):not(.layout-fixed-offcanvas) .layout-container {
  padding-top: 0 !important;
}

html:not(.layout-footer-fixed) .layout-content {
  padding-bottom: 0 !important;
}

@media (max-width: 991px) {
  html:not(.layout-navbar-fixed) .layout-1 .layout-container {
    padding-top: 0 !important;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sidenav,
  .layout-container {
    transition: none !important;
    transition-duration: 0s !important;
  }
}
@-webkit-keyframes layoutSidenavAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@-moz-keyframes layoutSidenavAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
@keyframes layoutSidenavAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}