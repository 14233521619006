.ui-square,
.ui-rect,
.ui-rect-30,
.ui-rect-60,
.ui-rect-67,
.ui-rect-75 {
  position: relative !important;
  display: block !important;
  padding-top: 100% !important;
  width: 100% !important;
}

.ui-rect {
  padding-top: 50% !important;
}

.ui-rect-30 {
  padding-top: 30% !important;
}

.ui-rect-60 {
  padding-top: 60% !important;
}

.ui-rect-67 {
  padding-top: 67% !important;
}

.ui-rect-75 {
  padding-top: 75% !important;
}

.ui-rect-content {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.ui-icon {
  display: inline-block;
  width: 46px;
  height: 46px;
  border: 2px solid;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  font-size: 18px;
  line-height: calc(46px - 4px);
}
.ui-icon.border-0 {
  line-height: 46px;
}
.ui-icon.border-center {
  line-height: calc(46px - 4px - 2px);
}

.ui-icon-sm {
  width: 30px;
  height: 30px;
  font-size: 14px;
  line-height: calc(30px - 4px);
}
.ui-icon-sm.border-0 {
  line-height: 30px;
}

.ui-bg-cover {
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-size: cover;
}

.default-style .ui-icon {
  border-color: rgba(24, 28, 33, 0.06);
}
.default-style .ui-bordered {
  border: 1px solid rgba(24, 28, 33, 0.06);
}