@import '@angular/material/theming';

// Dark/Light theme defined here
@import './buttons';

$border-radius: 20px;
$border-color: rgb(105, 105, 105);
$selected-background-color: rgb(225, 225, 225);
$cb-blue: #1f69ff !default;

mat-horizontal-stepper.hide-header {
  .mat-horizontal-stepper-header-container {
    display: none !important;
  }
}

mat-horizontal-stepper.p-0 {
  .mat-horizontal-content-container {
    padding: 0 !important;
  }
}

mat-tab-group.hide-tabs {
  > .mat-tab-header {
    display: none;
  }
}

.mat-radio-group {
  width: 100%;
  .mat-radio-button.w-100 {
    .mat-radio-label-content {
      width: 100%;
      white-space: normal;
    }
  }
  .mat-radio-label {
    margin:0px;
  }
}

.mat-chip.mini-chip,
.mat-chip.sm {
  min-height: 24px;
  font-size: 12px !important;
  opacity: 1 !important;
}

.mat-chip.xs {
  min-height: 0;
  font-size: 10px !important;
  padding: 8px 10px;
}

mat-radio-button {
  &.hide-ripple {
    .mat-radio-ripple {
      display: none;
    }
  }
}
lc-textbox,
lc-phone-number,
lc-dropdown,
lc-multiselect,
lc-textarea,
lc-agent-search {
  width: 100%;

  &.center-suffix {
    .mat-form-field-suffix {
      top: -5px!important;
    }
  }


  .mat-form-field.mat-form-field-readonly {
    @extend .mat-form-field-disabled;
  }

  &.hide-hint {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }
}


.mat-icon-button.no-focus ::ng-deep .mat-button-focus-overlay {
  display: none;
}


.mat-list.height-auto {
  .mat-list-item {
    height: unset;
    white-space: normal;
    margin: 0;
  }
}

/*
* Hide the arrow if elements are selected
*/
mat-form-field.selected {
  .mat-select-arrow {
    color: transparent !important;
  }
}

mat-option.line-height-unset {
  line-height: unset !important;
}

/**
 * This mixin is to make it easier to update the borders on the form-field
 * Currently it is only being used by this card-select.component.ts, but
 * if it is needed elsewhere, put it in the lc-material.scss
*/
@mixin formFieldBorders($borderStyle) {
  &.mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-top-style: $borderStyle;
    border-left-style: $borderStyle;
    border-bottom-style: $borderStyle;
    border-width: 1px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-top-style: $borderStyle;
    border-right-style: $borderStyle;
    border-bottom-style: $borderStyle;
    border-width: 1px;
  }

  &.mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-bottom-style: $borderStyle;
    border-width: 1px;
  }
}

.hide-form-borders {
  mat-form-field:not(.mat-form-field-invalid) {
    @include formFieldBorders(none);
  }
  mat-form-field.mat-form-field-invalid {
    @include formFieldBorders(dashed);
  }
}

.mat-drawer-container {
  height: 100%;
  width: 100%;
  margin-top: 2px;
  background-color: #f2f5f6;
  .mat-drawer {
    overflow-x: hidden;
    .mat-drawer-inner-container {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      box-sizing: content-box;

      /* Hide scrollbar for Chrome, Safari and Opera */
      &::-webkit-scrollbar {
        display: none;
      }
      /* Hide scrollbar for IE and Edge */
      -ms-overflow-style: none;
    }
  }
}

.overlay-container {
  position: relative;

  .overlay {
    position: absolute;

    &.top-right {
      top: 5px;
      right: 5px;
    }
  }
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(245, 245, 245, 0.75);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
* Styles for the material-table
*/
.table-container {
  width: 100%;
  position: relative;

  .loading-overlay.with-pager {
    bottom: 56px; // 56px from the bottom so that it doesnt cover up the paginator
  }

  .table-wrapper {
    position: relative;
    overflow: auto;
    min-height: 100px; // Large enough for the spinner

    table {
      width: 100%;
    }
  }

  .mat-column-action,
  .mat-column-actions {
    text-align: right;
    &.mat-header-cell {
      text-align: center;
    }
  }
}

@mixin mat-icon-size($size) {
  height: $size !important;
  width: $size !important;
  line-height: $size !important;
  font-size: $size !important;
}

.mat-icon-button {
  outline: none !important;

  &.sm {
    height: 24px;
    width: 24px;
    line-height: 24px;

    .mat-icon {
      @include mat-icon-size(20px);
    }
  }

  &.xs {
    height: 20px;
    width: 20px;
    line-height: 20px;

    .mat-icon {
      @include mat-icon-size(16px);
    }
  }
}

.mat-icon-button.large {
  width: 48px;
  height: 48px;
  line-height: 48px;

  .mat-icon {
    @include mat-icon-size(48px);
  }
}

.mat-icon.xs {
  @include mat-icon-size(14px);
}

.mat-icon.small {
  @include mat-icon-size(18px);
}

.mat-icon.md {
  @include mat-icon-size(24px);
}

.mat-icon.large,
.mat-icon.lg {
  @include mat-icon-size(48px);
}

.mat-icon.xl {
  @include mat-icon-size(62px);
}

.mat-icon.size-34 {
  @include mat-icon-size(34px);
}

mat-form-field {
  // have the formfield take up full width
  width: 100%;

  .mat-form-field-infix {
    width: auto !important;
  }
  &.mat-form-field-appearance-outline {
    /*
    * Style for the "X" button
    */
    .mat-form-field-suffix {
      padding: 0;
      top: 0;

      .mat-icon {
        font-size: 18px;
        position: relative;
        top: 3px;
      }
    }
  }
}

/*
* Fix the placement of the dropdown arrow
*/
.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  transform: none !important;
}

/*
* Style to make the select options match the cb blue
*/
mat-pseudo-checkbox {
  &.mat-option-pseudo-checkbox.mat-pseudo-checkbox-checked {
    background-color: $cb-blue;
  }
}

/*
* Style for the "Dropdown Header" which is actually just a disabled option
*/
mat-option.header-option {
  line-height: 1em !important;
  height: 2.5em !important;
  font-weight: bold;

  mat-pseudo-checkbox {
    display: none;
  }

  .mat-option-text {
    line-height: 1em;
  }
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: $cb-blue;
}


.cdk-overlay-pane.p-0 {
  .mat-dialog-container {
    padding: 0 !important;
  }
}
