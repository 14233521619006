/**
* Material Theme: Mat Mini Fab button overrides
*/
@mixin miniFabSize($size, $fontSize, $marginTopOffset) {
  width: $size;
  height: $size;

  .mat-icon {
    margin-top: $marginTopOffset;
    font-size: $fontSize;
  }
}

button.mat-mini-fab, a.mat-mini-fab {
  &.xs {
    @include miniFabSize(24px, 16px, -9px);
  }

  &.sm {
    @include miniFabSize(32px, 20px, -5px);
  }
  &.md {
    @include miniFabSize(40px, 20px, -5px);
  }
}
